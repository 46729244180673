import Vue from 'vue';
import VueRouter from 'vue-router';
import App from "@/App";
import Main from "@/components/Main";
import Boutique from "@/components/Boutique/Boutique";
import SingleProduct from "@/components/Boutique/SingleProduct";
import Contact from "@/components/Contact";
import Galerie from "@/components/Galerie";
import Presentation from "@/components/Presentation";
import ProfileOrders from "@/components/Connection/ProfileOrders";
import Signup from "@/components/Connection/Signup";
import Signin from "@/components/Connection/Signin";
import Cart from "@/components/Cart/Cart";
import Success from "@/components/Cart/Success";
import CreateOrder from "@/components/Cart/CreateOrder";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            component: App
        },
        {
            path: '/',
            component: Main
        },
        {
            path: '/la-carte',
            component: Boutique
        },
        {
            path: '/la-carte/:id',
            component: SingleProduct
        },
        {
            path: '/panier',
            component: Cart
        },
        {
            path: '/recapitulatif',
            component: CreateOrder
        },
        {
            path: '/success',
            component: Success
        },
        {
            path: '/presentation',
            component: Presentation
        },
        {
            path: '/galerie',
            component: Galerie
        },
        {
            path: '/contact',
            component: Contact
        },
        {
            path: '/compte',
            component: ProfileOrders
        },
        {
            path: '/inscription',
            component: Signup
        },
        {
            path: '/connexion',
            component: Signin
        },
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

