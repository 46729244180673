<template>
    <div class="Client-post">
        <div class="back">
            <router-link class="is-tab nav-item job_title back_button" to="/la-carte"><span>Retour</span>
            </router-link>
        </div>
        <div class="article_single">
            <div class="d-flex justify-content-center loader visibility-hidden" v-if="loader">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="media-content d-flex">
                <h3 class="article_title">{{ article.name }}</h3>
                <div class="d-flex product">
                    <div class="popular_img">
                        <img :src="article.images[0].src" alt="">
                    </div>
                    <ProductDescription :article="article" :varients="varients">
                    </ProductDescription>
                </div>
            </div>
            <div class="info-content d-flex">
                <h4 class="description-title">Description du produit :</h4>
                <p class="popular_desc" v-html="article.description"></p>
                <AccordionInfo :article="article" class="info_accordeon"></AccordionInfo>
            </div>
        </div>
    </div>
</template>

<script>
import ProductDescription from "./ProductDescription";
import AccordionInfo from "@/components/Boutique/AccordionInfo";

export default {
    name: "SingleProduct",
    components: {
        ProductDescription,
        AccordionInfo
    },
    data() {
        return {
            article: {},
            image: '',
            varients: []
        }
    },
    created() {
        this.$woocommerce.get("products/" + this.$route.params.id)
            .then(response => {
                this.article = response.data
                this.image = response.data.images[0].src
                response.data.meta_data[2].value = 0
                let test = response.data.meta_data[2].value
                test += 1
                test -= 1
                response.data.meta_data[2].value = test
            }, error => {
                alert(error)
            });
        this.$woocommerce.get("products/" + this.$route.params.id + "/variations?order=asc&orderby=id&per_page=20")
            .then(response => {
                for (let varient in response.data) {
                    let id = response.data[varient].id;
                    let textOption = "";
                    for (let attribute in response.data[varient].attributes) {
                        if (response.data[varient].attributes[attribute].name !== "Lien Stripe") {
                            textOption += response.data[varient].attributes[attribute].option + ", "
                        }
                    }
                    let lien = response.data[varient].attributes.find(element => element.name === "Lien Stripe");
                    let details = {
                        id: id,
                        text: textOption + response.data[varient].price,
                        stripe: lien.option
                    };
                    this.varients.push(details);
                }
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    },
}
</script>

<style scoped>

.Client-post {
    padding-top: 10%;
}

.back {
    position: absolute;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
}

.back_button {
    background: url("../../assets/left-arrow.webp") no-repeat left top;
    background-size: 20%;
    padding-left: 20px;
    color: #000000;
}

.article_title {
    font-family: Pacifico, cursive;
    font-size: 24px;
}

.loader {
    margin-top: 150px;
    margin-bottom: 150px;
}

.media-content {
    flex-direction: column;
}

.info-content {
    flex-direction: column;
    margin: 20px 10% 0px;
    width: 80%;
    text-align: left;
}

.article_single {
    margin-top: 40px;
}

.product {
    margin: 50px 10% 20px;
    width: 80%;
    text-align: left;
    flex-direction: column;
}

.description-title {
    text-align: left;
    font-family: Pacifico, cursive;
}

.popular_img {
    max-width: 100%;
}

.popular_img img {
    width: 100%;
    background-color: black;
}

@media (min-width: 992px) {
    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 7%;
    }

    .popular_img {
        max-width: 45%;
    }

    .product {
        flex-direction: row;
    }
}

@media (min-width: 1600px) {
    .Client-post {
        padding-top: 4%;
    }
}

</style>
