<template>
    <div class="recap-in">
        <div class="recap_list">
            <ul v-for="cartArticle in cartArticles" :key="cartArticle.id" class="d-flex flex-row recap_item">
                <li class="recap_info d-flex flex-column">
                    <h3 class="recap_title">{{ cartArticle.name }}</h3>
                    <span class="recap_count">Quantité : {{ cartArticle.quantity }}</span>
                </li>
                <li class="recap_price d-flex flex-row">
                    <span class="article_price">{{ cartArticle.price * cartArticle.quantity }} €</span>
                </li>
            </ul>
        </div>
        <div class="shipping">
            <span class="shipping_info">TOTAL : </span>
            <span class="shipping_info">{{ totalProduct }} €</span>
        </div>
        <router-link class="procced" to="/recapitulatif">Continuer</router-link>
    </div>
</template>

<script>

export default {
    name: "CartRecap",
    props: ['cartArticles'],
    data() {
        return {};
    },
    computed: {
        totalProduct() {
            let singleTotal = [];
            this.cartArticles.forEach(total => {
                let value = "";
                value = Number(total.price.toString()) * total.quantity
                singleTotal.push(value)
            })

            let total = singleTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            return total.toFixed(2);
        }
    },
}
</script>

<style scoped>

.recap_info {
    width: 280px;
}

.recap_list {
    padding-bottom: 10px;
    border-bottom: 1px solid #707070;
    margin-bottom: 10px;
}

.recap_item {
    padding-left: 0;
}

.recap_title {
    width: max-content;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
}

.recap_count {
    text-align: left;
    font-weight: 500;
    font-size: 12px;
}

.recap_price {
    margin-left: 20px;
}

.article_price {
    width: max-content;
    font-weight: 500;
    font-size: 14px;
    text-align: right;
}

.shipping {
    padding-bottom: 10px;
    border-bottom: 1px solid #707070;
    margin-bottom: 10px;
}

.shipping_info {
    width: 100px;
    text-align: left;
}

.shipping_price {
    margin-left: 100px;
}

.procced {
    font-weight: 400;
    font-size: 16px;
    color: white;
    background-color: #000000;
    border: none;
    padding: 10px 50px;
}

@media (min-width: 576px) {
    .recap_item {
        width: 550px;
    }

    .recap_price {
        margin-left: 150px;
    }
}

@media (min-width: 768px) {
    .recap_item {
        width: 100%;
    }

    .recap_info {
        width: 480px;
    }
}

@media (min-width: 992px) {
    .recap_price {
        margin-left: 20px;
    }

    .shipping_info {
        width: 280px;
        margin-left: 30px;
    }
}
</style>
