<template>
    <div class="Client-post">
        <div class="d-flex flex-column justify-content-center loader visibility-hidden " v-if="loader">
            <div class="logo_loader">
                <img src="../../assets/LOGO_DETOURE.webp" alt="Logo Bus-Stop-Diner">
            </div>
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="connection">
            <h2>Connexion</h2>
        </div>
        <div class="formulaire d-flex">
            <form @submit="trySubmit" class="text-left formulaire_content">
                <div class="form-group">
                    <label>Email</label>
                    <input class="form-control" v-model="form.email" type="text">
                </div>
                <div class="form-group">
                    <label>Mot de passe</label>
                    <input class="form-control" v-model="form.password" type="password">
                </div>
                <div v-if="errors.length">
                    <p class="text-danger">{{ errors }}</p>
                </div>
                <button class="submit_button">Connexion</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "Sigin",
    data() {
        return {
            errors: "",
            form: {
                email: "",
                password: "",
            },
            loader: true,
        };
    },
    methods: {
        trySubmit(e) {
            e.preventDefault();
            if (this.isValid()) {
                this.$store.dispatch("user/trySignin", this.form)
               let error = this.$store.getters["user/errors"]
               if (error === "Wrong user credentials."){
                    this.errors = "Identifiants incorrects."
               }
            }
        },
        isValid() {
            return true;
        },
        loaderToggle() {
            setTimeout(() => {
                this.loader = false
            }, 1000)
        }
    },
    mounted() {
        this.loaderToggle()
    }
}
</script>

<style scoped>

.loader {
    position: fixed;
    z-index: 1000000;
    background-color: #fbe9d5;
    width: 100%;
    height: 150%;
    margin-top: -60vh;
    overflow: hidden !important;
}

.logo_loader {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.logo_loader img {
    margin-top: 30px;
    width: 70%;
}

.spinner-border {
    margin-left: 46%;
    margin-right: 46%;
    margin-top: 70px;
}

.connection {
    font-family: 'Pacifico', cursive;
    margin-top: 45px;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
}

.formulaire {
    justify-content: left;
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
    padding-top: 4%;
    padding-bottom: 14%;
}

.formulaire_content {
    width: 100%;
}

.wp-block-image img {
    width: 10%;
}

.submit_button {
    background: linear-gradient(to top left, #ffa199, white);
    border: 1px solid black;
    border-radius: 25px;
    padding: 3px 10px;
}

@media (min-width: 576px) {
    .logo_loader {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }

    .spinner-border {
        margin-left: 47%;
        margin-right: 47%;
        margin-top: 70px;
    }
}

@media (min-width: 768px) {
    .spinner-border {
        margin-left: 48%;
        margin-right: 48%;
        margin-top: 70px;
    }
}

@media (min-width: 992px) {
    .loader {
        width: 103%;
        margin-left: -19vw;
    }

    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 2%;
    }
}

@media (min-width: 1200px) {
    .loader {
        position: fixed;
        z-index: 1000000;
        background-color: #fbe9d5;
        width: 100%;
        height: 150%;
        margin-top: -550px;
        overflow: hidden !important;
    }

    .logo_loader {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }

    .logo_loader img {
        margin-top: 30px;
        width: 70%;
    }

    .spinner-border {
        margin-left: 49%;
        margin-top: 70px;
    }

    .Client-post {
        padding-top: 4%;
        padding-bottom: 15%;
    }
}

@media (min-width: 1600px) {
    .loader {
        width: 103%;
        margin-left: -19vw;
    }
}
</style>
