<template>
    <div class="Client-post d-flex">
        <div class="cart">
            <div class="d-flex justify-content-center loader visibility-hidden" v-if="loader">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="actu">
                <h2>Vos commandes</h2>
            </div>
            <div v-if="orders.length === 0" class="no_article d-flex flex-column">
                <span>Vous n'avez pas de commandes</span>
                <router-link class="is-tab nav-item article_link " to="/la-carte">
                    <button class="procced">VOIR LA CARTE</button>
                </router-link>
            </div>
            <div class="content d-flex flex-column" v-for=" order in orders" :key="order.id">
                <div class="info d-flex flex-row">
                    <h3>Commande n°{{ order.id }}</h3>
                    <h3>Statut : {{ order.status }}</h3>
                </div>
                <div class="details" v-for=" product in order.line_items" :key="product.id">
                    <p class="detail_info">{{ product.name }}</p>
                    <p class="detail_info">Prix : {{ product.price.toFixed(2) }} €</p>
                    <p>Quantité : {{ product.quantity }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Cart",
    data() {
        return {
            orders: [],
            products: [],
            user: {},
            loader: false,
        }
    },
    methods: {},
    mounted() {
        this.user = this.$store.getters["user/currentUser"]
        this.$woocommerce.get("orders?customer=" + this.user.id)
            .then((response) => {
                this.orders = response.data
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    },
}
</script>

<style scoped>

.Client-post {
    padding-top: 10%;
    flex-direction: column;
}

.cart {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

h2 {
    text-align: center;
}

h3 {
    font-size: 1.5rem;
}

.actu {
    font-family: 'Pacifico', cursive;
    text-align: left;
    font-weight: bold;
    margin: 45px 20px 50px;
}

.loader {
    margin-top: 390px;
    margin-bottom: 390px;
}

.content {
    text-align: left;
    padding: 20px;
    margin-bottom: 30px;
    background-color: #fbe9d5;
    margin-left: 20px;
    margin-right: 20px;
}

.info {
    justify-content: space-between;
}

.details {
    border-top: 1px solid #707070;
}

.detail_info {
    margin-bottom: 5px;
}

.article_img img {
    width: 100%;
}

li {
    text-decoration: none;
    list-style: none;
    text-align: left;
}

.procced {
    font-weight: 400;
    font-size: 16px;
    color: white;
    background-color: #000000;
    border: none;
    padding: 10px 50px;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 20px;
}

@media (min-width: 768px) {

    h3 {
        font-size: 1.75rem;
    }

}

@media (min-width: 992px) {
    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 7%;
        min-height: 100vh;
        flex-direction: row;
    }

    .cart {
        width: 90%;
    }

}

@media (min-width: 1200px) {
    .cart {
        width: 80%;
    }

}

@media (min-width: 1400px) {
    .cart {
        width: 70%;
    }

}

@media (min-width: 1600px) {

    .cart {
        width: 60%;
    }

}
</style>
