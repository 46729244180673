<template>
    <div class="Client-post">
        <div class="d-flex flex-column justify-content-center loader visibility-hidden" v-if="loader">
            <div class="logo_loader">
                <img src="../../assets/LOGO_DETOURE.webp" alt="Logo Bus-Stop-Diner">
            </div>
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="actu">
            <h2>La carte</h2>
        </div>
        <div class="sidebar_container">
            <Sidebar @get-id="selectionCategorie"></Sidebar>
        </div>
        <div class="boutique d-flex">
            <div class="container_article">
                <div class="grid_container">
                    <div v-for="popular in populars" :key="popular.id" class="d-flex flex-column success_product">
                        <div class="media-content d-flex flex-row">
                            <div class="popular_img">
                                <router-link class="is-tab nav-item job_title " :to="'/la-carte/' + popular.id">
                                    <img :src="popular.images[0].src" :alt="popular.images[0].alt">
                                </router-link>
                            </div>
                            <div class="d-flex flex-column">
                                <h3 class="popular_title">{{ popular.name }}</h3>
                                <p class="popular_desc" v-html="popular.description"></p>
                                <p class="popular_price" v-html="popular.price_html"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from "./Sidebar";

export default {
    name: "Boutique",
    components: {
        Sidebar
    },
    data() {
        return {
            populars: [],
            idCategorie: '',
            loader: true,
        }
    },
    methods: {
        selectionCategorie(valeur) {
            this.idCategorie = valeur
            this.populars = [];
            this.loader = true;
            this.$woocommerce.get("products?category=" + this.idCategorie + "&orderby=title&order=asc&per_page=100")
                .then(response => {
                    for (let popular in response.data) {
                        this.populars.push(response.data[popular]);
                        this.loader = false;
                    }
                })
                .catch((error) => {
                    console.log(error.response.data);
                    this.loader = true;
                });
        },
        loaderToggle() {
            setTimeout(() => {
                this.loader = false
            }, 1000)
        },
        async requestWordpress() {
            this.$woocommerce.get("products?category=71&order=asc&orderby=title")
                .then(response => {
                    for (let popular in response.data) {
                        this.populars.push(response.data[popular]);
                    }
                })
                .catch((error) => {
                    console.log(error.response.data);
                });
        }
    },
    mounted() {
        this.requestWordpress().then(this.loaderToggle)
    }
}
</script>

<style scoped>

.loader {
    position: fixed;
    z-index: 1000000;
    background-color: #fbe9d5;
    width: 100%;
    height: 150%;
    margin-top: -60vh;
    overflow: hidden !important;
}

.logo_loader {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
}

.logo_loader img {
    margin-top: 30px;
    width: 70%;
}

.spinner-border {
    margin-left: 46%;
    margin-right: 46%;
    margin-top: 70px;
}

.boutique {
    flex-wrap: nowrap;
    flex-direction: row;
}

.actu {
    font-family: 'Pacifico', cursive;
    margin-top: 45px;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
}

.sidebar_container {
    width: 90%;
    font-family: 'Pacifico', cursive;
    font-size: 14px;
    font-weight: 500;
    margin-left: 5%;
    margin-right: 5%;
}

.sidebar > p {
    text-align: center;
}

.container_article {
    width: 80%;
    margin: 20px 10%;
}

.success_product {
    margin-bottom: 20px;
}

.popular_img {
    max-width: 40%;
    margin-right: 15px;
}

.popular_img img {
    width: 100%;
    background-color: black;
}

.popular_title {
    font-weight: bold;
    font-family: 'Pacifico', cursive;
    font-size: 16px;
    text-align: left;
}

.popular_desc {
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin-bottom: 0;
}

.popular_price {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 12px;
    text-align: left;
}

@media (min-width: 576px) {
    .logo_loader {
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
    }

    .spinner-border {
        margin-left: 47%;
        margin-right: 47%;
        margin-top: 70px;
    }

    .success_product {
        margin-bottom: 30px;
    }

    .popular_img {
        max-width: 40%;
    }
}

@media (min-width: 768px) {
    .spinner-border {
        margin-left: 48%;
        margin-right: 48%;
        margin-top: 70px;
    }

    .sidebar_container {
        font-size: 16px;
    }

    .popular_img {
        width: 100%;
    }

    .popular_title {
        font-size: 16px;
    }

    .popular_price {
        font-size: 16px;
    }
}

@media (min-width: 992px) {
    .loader {
        width: 103%;
        margin-left: -19vw;
    }

    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 2%;
    }

    .popular_title {
        font-size: 18px;
    }

    .popular_price {
        font-size: 18px;
    }
}

@media (min-width: 1200px) {
    .loader {
        position: fixed;
        z-index: 1000000;
        background-color: #fbe9d5;
        width: 100%;
        height: 150%;
        margin-top: -550px;
        overflow: hidden !important;
    }

    .logo_loader {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }

    .logo_loader img {
        margin-top: 30px;
        width: 70%;
    }

    .spinner-border {
        margin-left: 49%;
        margin-top: 70px;
    }

    .Client-post {
        padding-top: 4%;
    }

    .popular_title {
        font-size: 18px;
    }

    .popular_price {
        font-size: 18px;
    }

    .sidebar_container {
        font-size: 18px;
    }
}

@media (min-width: 1600px) {
    .loader {
        width: 103%;
        margin-left: -19vw;
    }

    .popular_title {
        font-size: 18px;
    }

    .popular_price {
        font-size: 18px;
    }

    .sidebar_container {
        font-size: 24px;
    }
}

</style>
