<template>
    <div class="d-flex description_general">
        <mdb-accordion class="accordeon" :panes="panes"/>
    </div>
</template>

<script>
import {mdbAccordion} from 'mdbvue';

export default {
    name: "AccordionInfo",
    props: ['article', 'materialOptions', 'stoneOptions'],
    components: {
        mdbAccordion,
    },
    data() {
        return {
            panes: [
                {
                    title: 'LIVRAISON & RETOURS',
                    content: `Les commandes à emporter doivent se faire entre 12h et 14h45 maximum. <br>
                                  Elles seront traités au plus vite, en fonction de l'affluence au moment de la réservation du produit.
                                  Délai maximum moyen de 30 min de temps de préparation. <br><br>
                                  En cas d'erreur ou de problématique lors de la réception, merci de contacter directement le gérant au 06.71.17.93.91. <br><br>
                                  La priorité absolue du Bus est de satsifaire ses clients.`
                    // content: "Les commandes à emporter doivent se faire entre 12h et 14h45 maximum. " +
                    //     "Elles seront traités au plus vite, en fonction de l'affluence au moment de la réservation du produit. " +
                    //     "Délai maximum moyen de 30 min de temps de préparation. " +
                    //     "En cas d'erreur ou de problématique lors de la réception, merci de contacter directement le gérant au 06.71.17.93.91. " +
                    //     "La priorité absolue du Bus est de satsifaire ses clients."
                }
            ]
        };
    }
}
</script>

<style>

.description_general {
    flex-direction: column;
}

.accordeon {
    margin-bottom: 50px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    text-align: left;
    width: 100%;
}

.card {
    background-color: #fbe9d5 !important;
    border: none !important;
    border-top: 1px solid #707070 !important;
}

.card-header {
    background-color: #fbe9d5 !important;
    border: none !important;
    border-bottom: 1px solid #707070 !important;
}

.accordeon > div > div > h5 {
    font-family: Pacifico, cursive;
}

.accordeon > div > div > p {
    font-size: 16px;
    font-weight: 300;
    color: #000000;
}

.description {
    width: 90%;
    text-align: left;
    margin: 25px 10px;
}

p {
    font-size: 14px;
}

li {
    font-size: 14px;
}

@media (min-width: 768px) {
    .description {
        width: 90%;
        text-align: left;
        margin: 25px 40px;
    }
}

@media (min-width: 992px) {
    .description_general {
        flex-direction: row;
    }

    .accordeon {
        margin-bottom: 150px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 25px;
        font-weight: 300;
        color: #000000;
        text-align: left;
        width: 100%;
    }

    .description {
        width: 40%;
        text-align: left;
        margin: 50px 0px;
    }
}

</style>
