<template>
    <div class="info_article d-flex flex-column">
        <div class="article_header d-flex flex-column">
            <div class="article_composition d-flex flex-row">
            </div>
        </div>
        <p>Selectionnez une quantité :</p>
        <div class="quantity">
            <button class="lessButton" @click="article.meta_data[2].value -= 1">-</button>
            <span class="count">{{ article.meta_data[2].value }}</span>
            <button class="moreButton" @click="article.meta_data[2].value += 1">+</button>
        </div>
        <div v-if="varients.length > 0">
            <p>Variantes du produit :</p>
            <select v-model="selected">
                <option v-for="option in varients" :value="option" :key="option.id">
                    {{ option.text }} €
                </option>
            </select>
        </div>
        <span class="article_price d-flex flex-column" v-else>Prix : {{ article.price }} €</span>
        <button class="add_cart" @click="sentArticleToCart">AJOUTER AU PANIER</button>
    </div>
</template>

<script>

export default {
    name: "ProductDescription",
    props: ['article', 'varients'],
    data() {
        return {
            addCart: '',
            selected: '',
        }
    },
    methods: {
        sentArticleToCart() {
            let stripeCode = "";
            let productPrice = "";
            console.log(this.selected)
            if (this.varients.length === 0) {
                stripeCode = this.article.attributes[0].options[0];
                productPrice = this.article.price;
            } else {
                stripeCode = this.selected.stripe;
                let getPrice = this.selected.text.split(", ")
                productPrice = getPrice.splice(-1).toString()
                console.log(productPrice)
            }
            let addProduct = {
                product_id: this.article.id,
                variation_id: this.selected.id,
                image: this.article.images[0].src,
                name: this.article.name,
                description: this.article.description,
                variation: this.selected,
                stripe: stripeCode,
                price: productPrice,
                quantity: this.article.meta_data[2].value
            }
            console.log(addProduct)
            this.$store.commit('shop/addToCartList', addProduct)
            this.$fire({
                title: "Succès",
                text: "Le produit a bien été ajouté au panier !",
                type: "success",
                timer: 3000
            }).then(r => {
                console.log(r.value);
            });
        }
    }
}
</script>

<style scoped>

.info_article {
    margin: 50px 0% 0px;
    padding: 20px;
    width: 100%;
    text-align: left;
    background-color: #fbe9d5;
    height: fit-content;
}

ul {
    background: url("../../assets/rec.webp") no-repeat left top;
    background-size: 4%;
    padding-left: 25px;
    color: #000000;
    margin-left: 10px;
}

.add_cart {
    margin-top: 10%;
    margin-bottom: 10%;
    font-weight: 400;
    font-size: 12px;
    color: white;
    background-color: #000000;
    border: none;
    padding: 10px 10px;
}

.quantity {
    width: fit-content;
    height: 30px;
    background-color: #F6F6F6;
    border: 1px solid #707070;
    margin: 10px;
    font-weight: bold;
    font-size: 16px;
}

.lessButton {
    border: none;
    height: 24px;
    width: 25px;
    background-color: #F6F6F6;
    font-weight: bold;
    font-size: 16px;
}

.count {
    height: 34px;
    margin: 0 15px;
}

.article_price {
    font-size: 18px;
    text-align: left;
    margin-right: 10px;
    margin-top: 30px;
    width: max-content;
}

.moreButton {
    border: none;
    height: 24px;
    width: 25px;
    background-color: #F6F6F6;
    font-weight: bold;
    font-size: 16px;
}

@media (min-width: 576px) {

    ul {
        background-size: 3%;
        padding-left: 25px;
        margin-left: 10px;
    }
}

@media (min-width: 992px) {
    .info_article {
        margin: 0px 0% 0px 5%;
        padding: 20px;
        background-color: #fbe9d5;
    }
}

@media (min-width: 1600px) {
    ul {
        background-size: 1%;
        padding-left: 25px;
        margin-left: 10px;
    }
}
</style>
