<template>
    <div class="Client-post d-flex">
        <div class="cart">
            <div class="d-flex justify-content-center loader visibility-hidden" v-if="loader">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="actu">
                <h2>Mon Panier</h2>
            </div>
            <div v-if="cartArticles.length === 0" class="no_article d-flex flex-column">
                <span>Votre panier est vide</span>
                <router-link class="is-tab nav-item article_link " to="/la-carte">
                    <button class="procced">VOIR LA CARTE</button>
                </router-link>
            </div>
            <div v-for="cartArticle in cartArticles" :key="cartArticle.id" class="d-flex flex-column cart_item">
                <div class="media-content d-flex flex-row">
                    <div class="cart_img">
                        <router-link class="is-tab nav-item article_link " :to="'/la-carte/' + cartArticle.product_id">
                            <div class="article_img">
                                <img :src="cartArticle.image" alt="">
                            </div>
                        </router-link>
                    </div>
                    <div class="cart_info d-flex flex-column">
                        <div class=" d-flex flex-row">
                            <div class="cart_txt d-flex flex-column">
                                <h3 class="article_title">{{ cartArticle.name }}</h3>
                                <div v-if="cartArticle.variation !== ''">
                                    <ul class="detail_list">
                                        <li v-for="varText in cartArticle.variation.text.slice(0, -1)"
                                            :key="varText.id">{{
                                                varText
                                            }}
                                        </li>
                                        <li v-html="cartArticle.description"></li>
                                    </ul>
                                </div>
                                <ul class="detail_list" v-else>
                                    <li v-html="cartArticle.description"></li>
                                </ul>
                            </div>
                            <div class="cart_price d-flex flex-column">
                                <span class="article_price d-flex flex-column">{{ cartArticle.price }} €</span>
                            </div>
                        </div>
                        <div class="cart_option d-flex">
                            <div class="quantity">
                                <button class="lessButton" @click="quantityLess(cartArticle)">-</button>
                                <span class="count">{{ cartArticle.quantity }}</span>
                                <button class="moreButton" @click="quantityMore(cartArticle)">+</button>
                            </div>
                            <div>
                                <button class="delete" @click="deleteArticle(cartArticle)">
                                    <mdb-icon far icon="trash-alt"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="recap">
            <CartRecap :cartArticles="cartArticles"></CartRecap>
        </div>
    </div>
</template>

<script>
import {mdbIcon} from 'mdbvue';
import CartRecap from "./CartRecap";

export default {
    name: "Cart",
    components: {
        mdbIcon,
        CartRecap
    },
    data() {
        return {
            cart: [],
            cartArticles: [],
            loader: false,
        }
    },
    methods: {
        deleteArticle(cartArticle) {
            console.log(cartArticle)
            this.$store.commit('shop/removeToCartList', cartArticle)
        },
        quantityLess(cartArticle) {
            cartArticle.quantity -= 1
            this.calculSingleTotal(this.cartArticles)
        },
        quantityMore(cartArticle) {
            cartArticle.quantity += 1
            this.calculSingleTotal(this.cartArticles)
        },
        calculSingleTotal(cartArticle) {
            cartArticle.forEach(price => {
                if (price.sale_price === '') {
                    let quantity = price.meta_data[0].value
                    let singleprice = price.regular_price * quantity
                    price.meta_data[2].value = singleprice
                } else {
                    let quantity = price.meta_data[0].value
                    let singleprice = price.sale_price * quantity
                    price.meta_data[2].value = singleprice
                }
            })
        }
    },
    created() {
        this.cartArticles = this.$store.getters["shop/listToCart"]
        this.cartArticles.forEach(articles => {
            if (articles.variation.text) {
                let articleText = [];
                let articleString = articles.variation.text.toString();
                articleText = articleString.split(", ")
                articles.variation.text = articleText;
            }
        })
        console.log(this.cartArticles)
        this.calculSingleTotal(this.cartArticles);
    },
}
</script>

<style scoped>

.Client-post {
    padding-top: 10%;
    flex-direction: column;
}

.cart {
    width: 100%;
}

.actu {
    font-family: 'Pacifico', cursive;
    margin-top: 45px;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
}

.recap {
    width: 90%;
    background-color: #fbe9d5;
    margin: 20px;
    height: fit-content;
}

.recap-in {
    background-color: #fbe9d5;
    margin: 20px;
    height: fit-content;
}

.loader {
    margin-top: 390px;
    margin-bottom: 390px;
}

.cart_item {
    border-bottom: 1px solid #707070;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-left: 20px;
}

.cart_info {
    width: 100%;
}

.article_img {
    width: 152px;
    height: fit-content;
}

.article_img img {
    width: 100%;
}

.article_title {
    margin-bottom: 40px;
    margin-left: 15px;
    width: 100px;
    font-family: Pacifico, cursive;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

.article_price {
    font-size: 14px;
    text-align: left;
    margin-right: 10px;
    width: max-content;
}

.detail_list {
    padding: 0px 15px;
}

li {
    text-decoration: none;
    list-style: none;
    text-align: left;
}

.cart_option {
    width: 100%;
    position: relative;
}

.quantity {
    height: 37px;
    background-color: #fbe9d5;
    border: 1px solid #707070;
    margin: 0px;
    font-weight: bold;
    font-size: 14px;
}

.lessButton {
    border: none;
    height: 35px;
    width: 35px;
    background-color: #fbe9d5;
    font-weight: bold;
    font-size: 14px;
}

.count {
    height: 35px;
    margin: 0 15px;
}

.moreButton {
    border: none;
    height: 35px;
    width: 35px;
    background-color: #fbe9d5;
    font-weight: bold;
    font-size: 14px;
}

.delete {
    border: none;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    background-color: #fbe9d5;
    width: 60px;
    height: 37px;
    position: absolute;
    right: 5vw;
}

.procced {
    font-weight: 400;
    font-size: 16px;
    color: white;
    background-color: #000000;
    border: none;
    padding: 10px 50px;
}

@media (min-width: 576px) {
    .article_title {
        margin-bottom: 20px;
        margin-left: 15px;
        width: 300px;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
    }

    .delete {
        right: 10vw;
    }

    .recap {
        width: 94%;
    }
}

@media (min-width: 768px) {
    .article_img {
        width: 212px;
    }

    .quantity {
        margin: 10px 50px;
    }
}

@media (min-width: 992px) {
    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 7%;
        min-height: 100vh;
        flex-direction: row;
    }

    .cart {
        width: 60%;
    }

    .recap {
        width: 35%;
        margin: 13vh 30px 30px;
    }

    .recap-in {
        margin: 10px;
    }

    .delete {
        right: 3vw;
        margin-top: 10px;
    }

    .cart_info {
        width: 70%;
    }

    .article_img {
        width: 132px;
    }

    .quantity {
        margin: 10px 20px;
    }
}

@media (min-width: 1200px) {
    .cart_item {
        margin-left: 50px;
    }

    .recap-in {
        margin: 20px;
    }

    .delete {
        right: 1vw;
    }
}

@media (min-width: 1400px) {
    .cart_item {
        margin-left: 50px;
    }

    .delete {
        right: 0vw;
    }
}

@media (min-width: 1600px) {
    .recap {
        width: 30%;
        margin: 50px;
    }

    .recap-in {
        margin: 30px;
    }

    .detail_list {
        margin-bottom: 0;
    }
}
</style>
