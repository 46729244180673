<template>
    <div class="Client-post d-flex">
        <div class="cart">
            <div class="d-flex justify-content-center loader visibility-hidden" v-if="loader">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="actu">
                <h2>Votre commande a été prise en compte</h2>
            </div>
            <div v-for="cartArticle in cartArticles" :key="cartArticle.id" class="d-flex flex-column cart_item">
                <div class="media-content d-flex flex-row">
                    <div class="cart_img">
                        <router-link class="is-tab nav-item article_link " :to="'/la-carte/' + cartArticle.product_id">
                            <div class="article_img">
                                <img :src="cartArticle.image" alt="">
                            </div>
                        </router-link>
                    </div>
                    <div class="cart_info d-flex flex-column">
                        <div class=" d-flex flex-row">
                            <div class="cart_txt d-flex flex-column">
                                <h3 class="article_title">{{ cartArticle.name }}</h3>
                                <div v-if="cartArticle.variation !== ''">
                                    <ul class="detail_list">
                                        <li v-for="varText in cartArticle.variation.text.slice(0, -1)"
                                            :key="varText.id">{{
                                                varText
                                            }}
                                        </li>
                                        <li v-html="cartArticle.description"></li>
                                    </ul>
                                </div>
                                <ul class="detail_list" v-else>
                                    <li v-html="cartArticle.description"></li>
                                </ul>
                            </div>
                            <div class="cart_price d-flex flex-column">
                                <span class="article_price d-flex flex-column">{{ cartArticle.price }} €</span>
                            </div>
                        </div>
                        <div class="cart_option d-flex">
                            <div class="quantity">
                                <span class="count">Quantité : {{ cartArticle.quantity }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="procced" @click="submit">OK</button>
        </div>
    </div>
</template>

<script>

export default {
    name: "Cart",
    data() {
        return {
            cart: [],
            cartArticles: [],
            order: {},
            user: {},
            loader: false,
        }
    },
    methods: {
        submit() {
            this.$store.commit('shop/removeOrder')
            this.$router.push("/compte")
        },
    },
    mounted() {
        this.cartArticles = this.$store.getters["shop/listToCart"]
        this.user = this.$store.getters["user/currentUser"]
        this.order = this.$store.getters["shop/order"]
        const data = {
            set_paid: true,
            status: "on-hold",
            customer_id: this.user.id,
        };
        this.$woocommerce.put("orders/" + this.order.id, data)
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        console.log(this.order)
    },
}
</script>

<style scoped>

.Client-post {
    padding-top: 10%;
    flex-direction: column;
}

.cart {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

h2 {
    text-align: center;
}

.actu {
    font-family: 'Pacifico', cursive;
    margin-top: 45px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: bold;
}

.loader {
    margin-top: 390px;
    margin-bottom: 390px;
}

.cart_item {
    border-bottom: 1px solid #707070;
    padding-bottom: 30px;
    padding-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
}

.cart_info {
    width: 100%;
}

.article_img {
    width: 152px;
    height: fit-content;
}

.article_img img {
    width: 100%;
}

.article_title {
    margin-bottom: 40px;
    margin-left: 15px;
    width: 100px;
    font-family: Pacifico, cursive;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
}

.article_price {
    font-size: 14px;
    text-align: left;
    margin-right: 10px;
    width: max-content;
}

.detail_list {
    padding: 0px 15px;
}

li {
    text-decoration: none;
    list-style: none;
    text-align: left;
}

.cart_option {
    width: 100%;
    position: relative;
    padding: 0px 15px;
}

.procced {
    font-weight: 400;
    font-size: 16px;
    color: white;
    background-color: #000000;
    border: none;
    padding: 10px 50px;
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 20px;
}

@media (min-width: 576px) {
    .article_title {
        margin-bottom: 20px;
        margin-left: 15px;
        width: 300px;
        font-weight: bold;
        font-size: 18px;
        text-align: left;
    }

}

@media (min-width: 768px) {
    .article_img {
        width: 212px;
    }

}

@media (min-width: 992px) {
    .Client-post {
        background-image: url("../../assets/brick-wall-185086.webp");
        background-size: 25%;
        margin-left: 19%;
        margin-right: 19%;
        padding-top: 7%;
        min-height: 100vh;
        flex-direction: row;
    }

    .cart {
        width: 90%;
    }

    .cart_info {
        width: 70%;
    }

    .article_img {
        width: 132px;
    }

}

@media (min-width: 1200px) {
    .cart_item {
        margin-left: 50px;
    }

    .cart {
        width: 80%;
    }

}

@media (min-width: 1400px) {
    .cart_item {
        margin-left: 50px;
    }

    .cart {
        width: 70%;
    }

}

@media (min-width: 1600px) {

    .detail_list {
        margin-bottom: 0;
    }

    .cart {
        width: 60%;
    }

}
</style>
