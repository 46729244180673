import Vue from 'vue'
import Vuex from 'vuex'
import {instance, WooCommerce} from '@/components/Connection/http'
import router from '../router/index'
import VuexPersistence from "vuex-persist";

Vue.use(Vuex)

const shop = {
    namespaced: true,

    state: {
        cartList: [],
        order: {},
    },

    getters: {
        listToCart: state => {
            return state.cartList
        },
        order: state => {
            return state.order
        }
    },

    mutations: {
        addToCartList(state, addCart) {
            state.cartList.push(addCart)
        },

        createOrder(state, order) {
            state.order = order
        },

        removeToCartList(state, removeCart) {
            let index = state.cartList.indexOf(removeCart);
            state.cartList.splice(index, 1);
        },

        removeOrder(state) {
            state.order = {}
        }
    },

};

const user = {
    namespaced: true,
    state: {
        userData: {},
        isLoading: false,
        isLoggedIn: localStorage.getItem("jwtToken") ? null : false,
        jwtToken: localStorage.getItem("jwtToken"),
        errors: []
    },
    getters: {
        isLoading: state => state.isLoading,
        isLoggedIn: state => state.isLoggedIn,
        errors: state => state.errors,
        currentUser: state => state.userData,
        jwtToken: state => state.jwtToken,
        // currentUserData: state => state.userData,
    },
    actions: {

        async trySignin(context, credentials) {
            try {
                context.commit("updateIsLoading", true)
                const response = await instance.post("/?rest_route=/simple-jwt-login/v1/auth&email=" + credentials.email + "&password=" + credentials.password)
                const userData = await WooCommerce.get("customers?email=" + credentials.email)
                // console.log(response.data)
                // console.log(userData.data[0])
                const user = {jwt: response.data.data.jwt, userData: userData.data[0]}
                context.commit("signinSuccess", user)
                await router.push("/compte")
            } catch (err) {
                // console.log(err.response.data.data)
                context.commit("signError", err)
            }
        },
        async trySignup(context, user) {
            try {
                context.commit("updateIsLoading", true)
                await instance.post("/?rest_route=/simple-jwt-login/v1/users&user_login=" + user.user_login + "&email=" + user.email + "&password=" + user.password + "&first_name=" + user.first_name + "&last_name=" + user.last_name)
                context.commit("signupSuccess")
                await router.push("/connexion")
            } catch (err) {
                // console.log(err.response)
                context.commit("signError", err)
            }
        },
    },
    mutations: {

        addData(state, addUser) {
            state.userData = addUser
        },

        updateIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        signupSuccess(state) {
            state.isLoading = false
            state.errors = []
        },
        signError(state, errors) {
            state.isLoading = false
            state.errors = errors.response.data.data.message
        },
        signinSuccess(state, data) {
            state.isLoading = false
            state.errors = []
            state.isLoggedIn = true
            state.userData = data.userData
            state.jwtToken = data.jwt
            localStorage.setItem("jwtToken", data.jwt)
        },
        signOut(state) {
            state.jwtToken = null
            state.isLoggedIn = false
            state.userData = null
            localStorage.removeItem("jwtToken")
        },
    }
};


export default new Vuex.Store({
    modules: {
        shop,
        user
    },
    plugins: [new VuexPersistence().plugin]
},)
